
import Vue from 'vue'
import { ThisTypedComponentOptionsWithRecordProps } from 'vue/types/options'
import { postTrialMiniCourseSchedule } from '~/utility/fetchAPI'
import validatePostTrialMiniCourseSchedule from '~/utility/validation/validatePostTrialMiniCourseSchedule'
import { daysOfWeek } from '~/utility/utility'

interface Data {
  email: string
  lastName: string
  lastNameKana: string
  firstName: string
  firstNameKana: string
  interestedCourseSelected: string[]
  learningPurposeSelected: string | null
  entryMotivationLevelSelected: string | null
  mentoringCheckboxSelected: string[]
  asking: string
  errors: any
  applicationErrors: string[]
  isSubmitButton: boolean // 次へ進むボタン
  isSubmitLoading: boolean // ローディング中アラート
}

interface Methods {
  onSubmit: () => void
  dateToDateStr: (date: Date) => string
  dateToHourStr: (date: Date) => string
}

interface Computed {
  interestedCourseCategories: any
  learningPurposeOptions: any
  entryMotivationLevelOptions: any
  checkboxOptions: any
  appointmentBeginsAt: Date | null
  appointmentEndsAt: Date | null
}

interface Props {
  appointmentForm: any
  appointmentDate: Date
  range: number
  courses: string
  purpose: string
  pageUrl: string
  mentorTagIds: number[]
}

const options: ThisTypedComponentOptionsWithRecordProps<
  Vue,
  Data,
  Methods,
  Computed,
  Props
> = {
  props: {
    appointmentForm: {
      default: null,
    },
    appointmentDate: {
      default: null,
    },
    range: {
      default: 1,
    },
    courses: {
      default: '',
    },
    purpose: {
      default: '',
    },
    pageUrl: {
      default: '',
    },
    mentorTagIds: {
      default: () => [],
    },
  },
  data() {
    return {
      lastName: '',
      firstName: '',
      lastNameKana: '',
      firstNameKana: '',
      email: '',
      interestedCourseSelected: [],
      learningPurposeSelected: null,
      entryMotivationLevelSelected: null,
      mentoringCheckboxSelected: [],
      asking: '',
      errors: {},
      applicationErrors: [],
      isSubmitButton: true, // 次へ進むボタン
      isSubmitLoading: false, // ローディング中アラート
    }
  },
  async mounted() {
    try {
      await this.$recaptcha.init()
    } catch (e) {
      // 何もしない
    }
  },
  computed: {
    interestedCourseCategories() {
      if (!this.appointmentForm) return [{ id: '0', title: '読み込み中' }]
      return this.appointmentForm.interested_course_collection
    },
    learningPurposeOptions() {
      if (!this.appointmentForm) return [{ value: '0', text: '読み込み中' }]
      const options = Object.entries(
        this.appointmentForm.learning_purpose_collection_for_trial
      ).map((ment: any) => {
        return { value: `${ment[1]}`, text: ment[0] }
      }) as any
      options.unshift({ text: 'お選びください', value: null })
      return options
    },
    entryMotivationLevelOptions() {
      if (!this.appointmentForm) return [{ value: '0', text: '読み込み中' }]
      const options = Object.entries(
        this.appointmentForm.entry_motivation_level_collection_for_trial
      ).map((ment: any) => {
        return { value: `${ment[1]}`, text: ment[0] }
      }) as any
      options.unshift({ text: 'お選びください', value: null })
      return options
    },
    checkboxOptions() {
      if (!this.appointmentForm) return [{ value: '0', text: '読み込み中' }]
      return this.appointmentForm.mentoring_style_collection.map(
        (ment: [number, string][]) => {
          return { value: `${ment[0]}`, text: ment[1] }
        }
      )
    },
    appointmentBeginsAt() {
      if (!this.appointmentDate) return null
      return new Date(this.appointmentDate)
    },
    appointmentEndsAt() {
      if (!this.appointmentDate) return null
      const date = new Date(this.appointmentDate)
      date.setMinutes(date.getMinutes() + 30 * this.range)
      return date
    },
  },
  beforeDestroy() {
    this.$recaptcha.destroy()
  },
  methods: {
    async onSubmit() {
      this.errors = {}
      this.applicationErrors = []
      const token: string = await this.$recaptcha.execute('trialEntry')
      const body: any = {
        trial_appointment_account_form: {
          appointment_form: {
            begins_at: `${this.appointmentDate}`,
            interested_course_ids: this.interestedCourseSelected,
            learning_purpose: this.learningPurposeSelected,
            entry_motivation_level: this.entryMotivationLevelSelected,
            mentoring_style: this.mentoringCheckboxSelected,
            asking: this.asking,
            range: this.range,
            courses: this.courses,
            purpose: this.purpose,
            mentor_tag_ids: this.mentorTagIds,
          },
          trial_entry: {
            last_name: this.lastName,
            first_name: this.firstName,
            last_name_kana: this.lastNameKana,
            first_name_kana: this.firstNameKana,
            email: this.email,
            marketing_params: this.$marketingParams.get(),
          },
          page_url: this.pageUrl,
        },
        recaptcha_token: token,
      }
      validatePostTrialMiniCourseSchedule(body)
        .then(() => {
          postTrialMiniCourseSchedule(this.$api, 'htmlcss-trial', body)
            .then((response) => {
              this.$marketingParams.remove()
              if (!this.pageUrl) {
                window.top!.location.href = `${process.env.host}/lp-htmlcss-trial-schedule/completed`
              } else {
                window.top!.location.href = `${process.env.host}/lp-htmlcss-trial-schedule/completed?token=${response.token}&id=${response.id}`
              }
            })
            .catch((errors: any) => {
              this.applicationErrors = errors.errors
              // 次へ進むボタンを表示してローディングを非表示に
              this.isSubmitButton = true
              this.isSubmitLoading = false
            })
        })
        .catch((errors: any) => {
          this.errors = errors
          // 次へ進むボタンを表示してローディングを非表示に
          this.isSubmitButton = true
          this.isSubmitLoading = false
        })

      // 次へ進むボタンを非表示にしてローディングを表示
      this.isSubmitButton = false
      this.isSubmitLoading = true
    },
    dateToDateStr(date) {
      if (!date) return '-/- (-)'
      return `${date.getMonth() + 1}/${date.getDate()} (${daysOfWeek(
        date
      )}) ${String(date.getHours()).padStart(1, '0')}:${String(
        date.getMinutes()
      ).padStart(2, '0')}`
    },
    dateToHourStr(date) {
      if (!date) return '-:-'
      return `${String(date.getHours()).padStart(1, '0')}:${String(
        date.getMinutes()
      ).padStart(2, '0')}`
    },
  },
}

export default Vue.extend(options)
