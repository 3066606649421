/**
 * ミニコーススケジュール申し込みのバリデーション
 */

import * as validate from '~/utility/validation/validation';

export default (body: any): any => new Promise((resolve, reject) => {

  const {
    begins_at,
    interested_course_ids,
    learning_purpose,
    entry_motivation_level,
    mentoring_style,
    asking,
  } = body.trial_appointment_account_form.appointment_form
  const {
    last_name,
    first_name,
    last_name_kana,
    first_name_kana,
    email,
  } = body.trial_appointment_account_form.trial_entry

  // 検査対象のフィールドのエラーを空配列で定義する
  const validationErrors: any = {
    begins_at: [],
    interested_course_ids: [],
    learning_purpose: [],
    entry_motivation_level: [],
    mentoring_style: [],
    asking: [],
    last_name: [],
    first_name: [],
    last_name_kana: [],
    first_name_kana: [],
    email: [],
  };

  // 姓
  validate.required(last_name) && validationErrors.last_name.push(validate.required(last_name));
  validate.dependentChar(last_name) && validationErrors.last_name.push(validate.dependentChar(last_name));

  // 名
  validate.required(first_name) && validationErrors.first_name.push(validate.required(first_name));
  validate.dependentChar(first_name) && validationErrors.first_name.push(validate.dependentChar(first_name));

  // 姓（カナ）
  validate.required(last_name_kana) && validationErrors.last_name_kana.push(validate.required(last_name_kana));
  validate.dependentChar(last_name_kana) && validationErrors.last_name_kana.push(validate.dependentChar(last_name_kana));
  validate.fullKana(last_name_kana) && validationErrors.last_name_kana.push(validate.fullKana(last_name_kana));

  // 名（カナ）
  validate.required(first_name_kana) && validationErrors.first_name_kana.push(validate.required(first_name_kana));
  validate.dependentChar(first_name_kana) && validationErrors.first_name_kana.push(validate.dependentChar(first_name_kana));
  validate.fullKana(first_name_kana) && validationErrors.first_name_kana.push(validate.fullKana(first_name_kana));

  // メールアドレス
  validate.required(email) && validationErrors.email.push(validate.required(email));
  validate.email(email) && validationErrors.email.push(validate.email(email));

  // 興味のあるコース
  validate.requiredArray(interested_course_ids) && validationErrors.interested_course_ids.push(validate.requiredArray(interested_course_ids));

  // 学習目的
  validate.required(learning_purpose) && validationErrors.learning_purpose.push(validate.required(learning_purpose));

  // テックアカデミーの受講について
  validate.required(entry_motivation_level) && validationErrors.entry_motivation_level.push(validate.required(entry_motivation_level));

  // メンタリングスタイル
  validate.requiredArray(mentoring_style) && validationErrors.mentoring_style.push(validate.requiredArray(mentoring_style));

  // 質問
  validate.dependentChar(asking) && validationErrors.asking.push(validate.dependentChar(asking));
  
  if(Object.keys(validationErrors).every(key => validationErrors[key].length === 0)) {
    resolve(body);
  } else {
    reject(validationErrors);
  };
});
